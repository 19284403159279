.titleRow {
	display: flex;
	flex-direction: row;
	margin: 5px -1% 10px -1%;
	padding-left: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
	
	background: #e6f9ff;
	border-top: 1px solid #9FE0F7;
	border-bottom: 1px solid #9FE0F7;
	box-shadow: 0 3px 3px #dfdfdf;
	-webkit-box-shadow: 0 1px 3px #dfdfdf;
	-moz-box-shadow: 0 1px 3px #dfdfdf;
	color: #2b98ca;
}


.screenshotBox {
	display: inline-block;
	border: 1px solid black;
	text-align: center;
	margin: 5px 0 15px 0;
	padding:1px;
}
.screenshotBox img {
	max-width: 100%;
	max-height: 200px;
	margin: 1px;
}


/**********
notCompatibleIcon
**********/

.notCompatibleIcon {
	display: inline-block;
	position: relative;
	margin: 0 3px;
	vertical-align: middle;
	font-size: 12px; /* Changes the position of .deviceIcon::before*/
}
.notCompatibleIcon > span {
	line-height: unset;
	font-size: 9pt;
}
.notCompatibleIcon .deviceIcon {
	display: inline;
	position: relative;
}
.notCompatibleIcon .deviceIcon svg {
	width: 15px !important; /* we need important to override .dashRow .dashEl .dashIcon svg*/
	height: 15px !important;
	fill: #ff8fce !important; /* to counteract svg:hover*/
}
.dashEl .icon > .notCompatibleIcon {
	position: absolute;
}
.notCompatibleIcon .deviceIcon::before {
	content: "";
	display: block;
	position: absolute;
	top: 3px;
	left: -2px;
	right: -2px;
	border-top: 1px solid #dc4e9d;
	rotate: 45deg;
}

/**********
inlineIcon
**********/

.inlineIcon{
	display: inline;
	position: relative;
	margin: 0 3px;
	vertical-align: middle;
}

.inlineIcon > span {
	line-height: unset;
	font-size: 9pt;
}

.inlineIcon svg {
	width: 15px !important;
	height: 15px !important;
}

/**********
tabBar
**********/

.tabBar {
	z-index: 1;
	position: relative;
	display: flex;
	/*box-sizing: border-box;*/
	/*height: 40px;*/
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin: 0 -1% 0 -1%;
}
.tabBar.smallBar > .tab {
	font-size: 9pt;
	padding: 2px 5px;
}
.tabBar.smallBar > .selected {
	margin-top: -3px;
	padding-bottom: 5px; /*prevent below content from moving in case there are only 2 (or one) entries in a row*/
}

.tabBar > .tab {
	flex: 1 1 auto;
	border-left: 1px solid white;
	border-right: 1px solid white;
	border-top: 2px solid white;

	padding: 5px;
	margin-top: 0;
	background-color: #e6f9ff;
	font-weight: bold;
	color: #2b98ca;
	text-align: center;
	transition: background-color 0.5s, margin-top 0.1s, padding-bottom 0.1s, color 0.5s;
}
.tabBar > .tab svg {
	fill: #2b98ca;
	max-height: 20px;
}
.tabBar > .addBtn {
	max-width: 30px;
}
.tabBar > .highlight {
	color: #dc4e9d;
}
.tabBar > .selected {
	z-index: 1;
	background-color: #9fe0f7;

	margin-top: -5px;
	padding-bottom: 10px; /*prevent below content from moving in case there are only 2 (or one) entries in a row*/
}

.tabBar > .tab:not(.selected):hover {
	background-color: #9fe0f7;
	color: #2DBFF3;
	cursor: pointer;
}


/**********
iconLikeSpacer
**********/

.iconLikeSpacer {
	width: 24px;
}


/**********
ChartView
**********/

.chartView {
	position: relative;
	min-height: 290px;
}
.chartView .loaderAnimation {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 50px;
	height: 50px;
}